import styled, { css } from 'styled-components'
import { TextColor } from '../models/text-color'
import { fontSizes } from '../styles/theme'

type RibbonProps = {
  backgroundColor: string
  textColor: TextColor
  large: boolean
}

const Ribbon = styled.div`
  position: absolute;
  width: 170px;
  height: 30px;
  background-color: ${({ backgroundColor }: RibbonProps) => `#${backgroundColor.replace('#', '')}`};
  top: 0;
  left: 0;
  z-index: 21;
  transform: rotate(-45deg) translate(-28%, -50%);

  display: grid;
  align-items: center;
  justify-content: center;

  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: ${fontSizes.size600};
  box-shadow: ${props => props.theme.shadows.close};

  ${({ textColor }: RibbonProps) =>
    textColor === TextColor.LIGHT
      ? css`
          color: ${props => props.theme.colors.typography.light.text};
        `
      : css`
          color: ${props => props.theme.colors.typography.dark.text};
        `}

  ${({ large }: RibbonProps) =>
    large &&
    css`
      width: 220px;
      height: 40px;
      font-size: ${fontSizes.size500};
    `}
`

export default Ribbon
