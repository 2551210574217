import React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const BlockComponent = (node, children) => <>{children}</>

const BoxOfficeButtonText = ({ text }: { text: any }) => {
  // Contentful RichText will default to wrapping the content in a paragraph.
  // To remove that styling, we'll strip out all block elements and just render the text.
  const options = {
    renderNode: Object.values(BLOCKS).reduce(
      (options, block) => ({ ...options, [block]: BlockComponent }),
      {}
    )
  }

  return <>{documentToReactComponents(text, options)}</>
}

export default BoxOfficeButtonText
