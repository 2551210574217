import React from 'react'
import styled from 'styled-components'
import { transitionTimings, colors } from '../styles/theme'
import { TextColor } from '../models/text-color'
import Ribbon from './ribbon'

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-top: ${({ ratioX, ratioY }: { ratioX: number; ratioY: number }) =>
    `${(ratioY / ratioX) * 100}%`};
`

const Img = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: 20;
  transition: transform ${transitionTimings.fast} ease-in-out;

  background-image: url(${(props: { src: string }) => props.src});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`

const CroppedImg = ({
  src,
  alt,
  ratioX = 16,
  ratioY = 9,
  className,
  ribbon = false,
  ribbonText = '',
  ribbonLarge = false,
  ribbonBackgroundColor = colors.alert,
  ribbonTextColor = TextColor.LIGHT,
  ...other
}: {
  src: string
  alt: string
  className?: string
  ratioX?: number
  ratioY?: number
  ribbon?: boolean
  ribbonText?: string
  ribbonLarge?: boolean
  ribbonBackgroundColor?: string
  ribbonTextColor?: TextColor
}) => (
  <Wrapper className={className} ratioX={ratioX} ratioY={ratioY}>
    {ribbon && (
      <Ribbon
        large={ribbonLarge}
        backgroundColor={ribbonBackgroundColor}
        textColor={ribbonTextColor}
      >
        {ribbonText}
      </Ribbon>
    )}
    <Img src={src} {...other} role="img" aria-label={alt} />
  </Wrapper>
)

export default CroppedImg
