import React from 'react'
import styled, { css } from 'styled-components'
import EventCard, { CardVariant, listOrResponsive } from '../event-card/event-card'
import PACEvent from '../../models/event/pac-event'
import breakpoints from '../../styles/breakpoints'

const MIN_CARD_SIZE = '300px'

type StyledEventsGridProps = { variant: CardVariant; single?: boolean }

const StyledEventsGrid = styled.ol`
  display: grid;
  padding: 0;
  list-style-type: none;

  grid-template-columns: repeat(auto-fit, minmax(${MIN_CARD_SIZE}, 1fr));

  ${({ variant }: StyledEventsGridProps) =>
    listOrResponsive(
      css`
        grid-template-columns: 1fr;
        li:not(:last-of-type) {
          padding-bottom: ${props => props.theme.spacing.base400};
          border-bottom: 2px solid ${props => props.theme.colors.separator};
        }
      `,
      variant
    )}

  grid-gap: ${props => props.theme.spacing.base400};

  ${({ single = false, variant }: StyledEventsGridProps) =>
    single &&
    variant === 'card' &&
    css`
      @media (min-width: ${breakpoints.lg}) {
        grid-template-columns: minmax(${MIN_CARD_SIZE}, 500px);
      }
    `}
`

const StyledEventCard = styled(EventCard)`
  height: 100%;
`

const EventsGrid = ({
  events,
  variant = 'card',
  ...other
}: {
  events: PACEvent[]
  variant?: CardVariant
}) => {
  return (
    <StyledEventsGrid variant={variant} single={events.length === 1} {...other}>
      {events.length > 0 &&
        events.map((event: PACEvent) => {
          return (
            <li key={event.slug}>
              <StyledEventCard event={event} variant={variant} />
            </li>
          )
        })}
    </StyledEventsGrid>
  )
}

export default EventsGrid
