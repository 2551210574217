import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import theme, { colors, transitionTimings } from '../../styles/theme'
import Subheadline from '../system/typography/subheadline'
import { FaCalendarAlt, FaMapMarkerAlt } from 'react-icons/fa'
import Button from '../system/forms/button'
import breakpoints from '../../styles/breakpoints'
import Link from 'next/link'
import CroppedImg from '../cropped-img'
import PACEvent from '../../models/event/pac-event'
import { EventListingTypeContext, EventListingType } from '../events/event-listing-type'
import { EventStatus, eventStatusToDisplayName } from '../../models/event/event-status'
import LiveBug from '../stream-popout/live-bug'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import BoxOfficeButtonText from '../box-office-button/box-office-button-text'
import { useIsLive, useStreamEvent } from '../stream-popout/stream-provider'

export const listBreakpoint = breakpoints.lg

export const listOrResponsive = (styles: any, variant: CardVariant) => {
  if (variant === 'list') {
    return styles
  }

  if (variant === 'responsive') {
    return css`
      @media (min-width: ${listBreakpoint}) {
        ${styles}
      }
    `
  }
}

export const cardOrResponsive = (styles: any, variant: CardVariant) => {
  if (variant === 'card') {
    return styles
  }

  if (variant === 'responsive') {
    return css`
      @media (max-width: ${listBreakpoint}) {
        ${styles}
      }
    `
  }
}

const Card = styled.article`
  display: grid;
  align-items: center;
  cursor: pointer;
  transition: color ${props => props.theme.transitions.links} ease;

  &:hover {
    color: ${props => props.theme.colors.linkHoverColor};
  }

  ${({ variant }: { variant: CardVariant }) =>
    cardOrResponsive(
      css`
        grid-template-areas:
          'img'
          'details'
          'button';
        grid-template-rows: auto 1fr;
        overflow: hidden;
        border-radius: 3px;

        background-color: ${colors.white};
        box-shadow: ${theme.shadows.close};
        transition: box-shadow ${transitionTimings.fast} ease,
          transform ${transitionTimings.fast} ease;

        :hover {
          box-shadow: ${theme.shadows.hover};
          transform: translateY(-2px);
        }
      `,
      variant
    )}

  ${({ variant }: { variant: CardVariant }) =>
    listOrResponsive(
      css`
        grid-template: 'img details button';
        grid-template-columns: 0.35fr 1fr auto;
      `,
      variant
    )}
`

const StyledCardImg = styled(CroppedImg)`
  grid-area: img;
  ${({ variant }: { variant: CardVariant }) =>
    listOrResponsive(
      css`
        border-radius: 2px;
        box-shadow: ${props => props.theme.shadows.close};
      `,
      variant
    )}
`

const Details = styled.div`
  grid-area: details;
  display: grid;
  grid-template-rows: repeat(3, auto) 1fr;
  padding: ${theme.spacing.base400} ${theme.spacing.base500};
  height: 100%;

  grid-template-areas:
    'organizer'
    'name'
    'description'
    'metadata';

  ${({ variant }: { variant: CardVariant }) =>
    listOrResponsive(
      css`
        grid-template-areas:
          'name'
          'organizer'
          'description'
          'metadata';
      `,
      variant
    )}
`

const Organizer = styled(Subheadline)`
  grid-area: organizer;
`

const Name = styled.h3`
  grid-area: name;
  margin: 0;

  ${({ variant }: { variant: CardVariant }) =>
    cardOrResponsive(
      css`
        margin-bottom: ${theme.spacing.base300};
      `,
      variant
    )}
`

const Description = styled.p`
  grid-area: description;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  /* Prevent Safari from showing full description tooltip */
  &::before {
    display: block;
    content: '';
  }

  ${({ variant }: { variant: CardVariant }) =>
    cardOrResponsive(
      css`
        display: none;
      `,
      variant
    )}
`

const Metadata = styled.dl`
  grid-area: metadata;
  align-content: end;

  margin: 0;

  ${({ variant }: { variant: CardVariant }) =>
    listOrResponsive(
      css`
        align-self: end;
        grid-template-columns: repeat(2, auto 1fr);
      `,
      variant
    )}

  dd {
    margin-left: 0;
  }

  color: ${theme.colors.typography.dark.subtext};
`

export type CardVariant = 'card' | 'list' | 'responsive'

const MoreDetails = styled(Button)`
  grid-area: button;
  display: none;
  padding-top: ${props => props.theme.spacing.base400};
  padding-bottom: ${props => props.theme.spacing.base400};

  ${({ cardVariant }: { cardVariant: CardVariant }) =>
    listOrResponsive(
      css`
        display: block;
      `,
      cardVariant
    )}
`

const DateIcon = styled(FaCalendarAlt)`
  ${({ status }: { status: EventStatus }) =>
    status !== EventStatus.ACTIVE &&
    css`
      color: ${colors.alert};
    `}
`

const DateRangeText = styled.span`
  ${({ dateChanged }: { dateChanged: boolean }) =>
    dateChanged &&
    css`
      text-decoration: line-through;
      color: ${colors.alert};
      font-weight: bold;
    `}
`

const LiveNowLabel = styled.span`
  text-transform: uppercase;
  color: ${colors.alert};
  font-weight: bold;
`

const EventCard = ({
  event,
  variant = 'card',
  ...other
}: {
  event: PACEvent
  variant?: CardVariant
}) => {
  const isLive = useIsLive(event)
  const listingType = useContext(EventListingTypeContext)
  const plainTextDescription = documentToPlainTextString(event.description)

  const eventActive = event.status === EventStatus.ACTIVE

  const card = (
    <Card variant={variant} {...other}>
      <StyledCardImg
        src={`${event.promotionImage.url}?w=500`}
        alt={event.promotionImage.title}
        variant={variant}
        ribbon={!eventActive || !!event.ribbon}
        ribbonText={
          !eventActive
            ? eventStatusToDisplayName(event.status)
            : event.ribbon
            ? event.ribbon.text
            : ''
        }
        ribbonBackgroundColor={event.ribbon ? event.ribbon.backgroundColor : undefined}
        ribbonTextColor={event.ribbon ? event.ribbon.textColor : undefined}
      />
      <Details variant={variant}>
        <Organizer>{event.organizer.name}</Organizer>
        <Name variant={variant}>{event.name}</Name>
        <Description variant={variant}>{plainTextDescription}</Description>
        <Metadata variant={variant}>
          {isLive && (
            <>
              <dt>
                <LiveBug />
              </dt>
              <dd>
                <LiveNowLabel>Live Now!</LiveNowLabel>
              </dd>
            </>
          )}
          <dt>
            <DateIcon title="Date" status={event.status} />
          </dt>
          <dd>
            <DateRangeText dateChanged={event.status !== EventStatus.ACTIVE}>
              {event.dateRange}
            </DateRangeText>
          </dd>
          <dt>
            <FaMapMarkerAlt title="Location" />
          </dt>
          <dd>{event.location.name}</dd>
        </Metadata>
      </Details>
      <MoreDetails cardVariant={variant} aria-hidden="true">
        {listingType === EventListingType.PROGRAM ? (
          <>
            View <b>Program</b>
          </>
        ) : listingType === EventListingType.LIVESTREAM ? (
          <>
            View <b>Stream</b>
          </>
        ) : listingType === EventListingType.TICKETED ? (
          <BoxOfficeButtonText text={event.boxOfficeButtonAppearance.activeText} />
        ) : (
          <>
            More <b>Details</b>
          </>
        )}
      </MoreDetails>
    </Card>
  )

  if (listingType === EventListingType.PROGRAM) {
    return (
      <a
        href={event.programUrl}
        rel="noopener"
        className="unstyled"
        aria-label={`${event.name} presented by ${event.organizer}. ${event.dateRange} at ${event.location.name}. ${plainTextDescription}`}
      >
        {card}
      </a>
    )
  }

  if (listingType === EventListingType.TICKETED && event.boxOfficeUrl) {
    return (
      <a
        href={event.boxOfficeUrl}
        rel="noopener"
        className="unstyled"
        aria-label={`${event.name} presented by ${event.organizer}. ${event.dateRange} at ${event.location.name}. ${plainTextDescription}`}
      >
        {card}
      </a>
    )
  }

  return (
    (<Link
      href="/events/[slug]"
      as={`/events/${event.slug}`}
      className="unstyled"
      aria-label={`${event.name} presented by ${event.organizer}. ${event.dateRange} at ${event.location.name}. ${plainTextDescription}`}>

      {card}

    </Link>)
  );
}

export default EventCard
