export enum EventStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  POSTPONED = 'POSTPONED'
}

export const eventStatusFromDisplayName = (displayName: string): EventStatus => {
  const defaultStatus = EventStatus.ACTIVE
  return displayName ? EventStatus[displayName.toUpperCase()] || defaultStatus : defaultStatus
}

export const eventStatusToDisplayName = (eventStatus: EventStatus): string => {
  if (!eventStatus) {
    return eventStatusToDisplayName(EventStatus.ACTIVE)
  }

  return eventStatus.charAt(0).toUpperCase() + eventStatus.substr(1).toLowerCase()
}
