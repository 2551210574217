import styled, { css } from 'styled-components'
import { transitionTimings, colors } from '../../../styles/theme'

const ButtonStyle = css`
  width: 100%;
  background-color: ${props => props.theme.forms.button.backgroundColor};
  color: ${props => props.theme.forms.button.color};
  padding: ${props => props.theme.spacing.base300} ${props => props.theme.spacing.base500};
  border-radius: 3px;
  text-align: center;
  font-family: ${props => props.theme.forms.button.font};
  font-weight: lighter;
  text-transform: uppercase;
  transition: background-color ${transitionTimings.fast} ease;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.forms.button.color};
    background-color: ${props => props.theme.forms.button.hoverBackgroundColor};
  }

  &:disabled {
    background-color: ${colors.neutral600};
    color: ${colors.neutral300};
    cursor: not-allowed;
  }

  ${({ variant = 'solid' }: { variant?: 'solid' | 'outlined' }) =>
    variant === 'outlined' &&
    css`
      background-color: transparent;
      border: 2px solid ${props => props.theme.forms.button.backgroundColor};
      color: ${props => props.theme.forms.button.backgroundColor};
    `}
`

export const ButtonLink = styled.a`
  ${ButtonStyle}
`

const Button = styled.button`
  ${ButtonStyle}
  border: 0;
  outline: none;
  font-size: inherit;
  height: fit-content;
`

export default Button
