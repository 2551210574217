import React from 'react'

export enum EventListingType {
  EVENT,
  PROGRAM,
  LIVESTREAM,
  TICKETED
}

export const EventListingTypeContext = React.createContext(EventListingType.EVENT)
